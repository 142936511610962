import { Component, Vue } from 'vue-property-decorator'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import EmptyTimeLine from '@/components/shared/EmptyTimeLine.vue'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { PediatryEntourageModel, PediatryPADModel } from '@/models/podiatry-observation-model'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { PediatryService } from '@/services/patient-pediatry-service'
import { AxiosResponse } from 'axios'
import { pointsDiscutesTypes } from '@/views/Patients/Dossier/Constants'
import { ValidationObserver } from 'vee-validate'
import { EntourageHeaders } from '@/views/Patients/Static'
import Commons from '@/components/shared/Helpers/commons'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

@Component({
  components: {
    Confirm,
    Alert,
    ValidationObserver,
    EmptyTimeLine
  }
})
export default class PediatryEntourage extends Vue {
  private pediatryService = PediatryService.getInstance()
  private selectedItem: PediatryEntourageModel = {}
  private today = Commons.GetTodayFormatted()
  private selectedPad: PediatryPADModel = { date: this.today }
  private dossierId!: string

  public entourageItems: PediatryEntourageModel[] = []
  public PADItems: PediatryPADModel[] = []
  public showConfirm = false
  public showPadCalenderDialog = false
  public initLoading = false
  public isPadLoading = false
  public confirmContext: 'PAD' | 'Entourage' | '' = ''
  public readonly PAD_CONFIRM_CONTEXT = 'PAD'
  public readonly ENTOURAGE_CONFIRM_CONTEXT = 'Entourage'
  public errorMessages: string[] = []
  public headers = EntourageHeaders
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public mounted () {
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (Commons.isCachedDossierPatientValid(cachedDossier)) {
      const selectedDossier = JSON.parse(cachedDossier!) as dossierPatientModel
      this.dossierId = selectedDossier.guid
      this.getEntourages()
      this.getPADs()
    } else {
      this.$router.push({ name: 'patient' })
    }
  }

  public get deleteConfirmMessage () {
    return (this.confirmContext === this.ENTOURAGE_CONFIRM_CONTEXT
      ? 'Cette action ne peut être annulée. Voulez-vous continuer et supprimer cette information à l’entourage ?'
      : 'Cette action ne peut être annulée. Voulez-vous continuer et supprimer ce PAD ?')
  }

  public get deleteConfirmTitle () {
    return (this.confirmContext === this.ENTOURAGE_CONFIRM_CONTEXT
      ? 'Supprimer cette information à l’entourage'
      : 'Supprimer ce PAD')
  }

  private getEntourages () {
    this.initLoading = true
    this.pediatryService.GetDossierPediatryEntourages(this.dossierId)
      .then(entourages => {
        this.entourageItems = entourages
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.initLoading = false
      })
  }

  private getPADs () {
    this.isPadLoading = true
    this.pediatryService.GetDossierPADs(this.dossierId)
      .then(pads => {
        this.PADItems = pads
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.isPadLoading = false
      })
  }

  public getEvalText (id: number) {
    return pointsDiscutesTypes.find(x => x.value === id)?.text
  }

  public addPediatryEntourage () {
    const path = `/patient/${this.dossierId}/consultation/pediatry/entourage/new`
    this.$router.push(path)
  }

  public editPediatryEntourage (item: PediatryEntourageModel) {
    this.selectedItem = item
    this.pediatryService.updatePediatryEntourageSelected(this.selectedItem)
    const path = `/patient/${this.dossierId}/consultation/pediatry/entourage/${item.id}`
    this.$router.push(path)
  }

  public displayDeleteConfirmDialog (entourage: PediatryEntourageModel) {
    this.showConfirm = true
    this.confirmContext = this.ENTOURAGE_CONFIRM_CONTEXT
    this.selectedItem = entourage
  }

  public async confirmDeleteCallback (value: boolean) {
    if (this.confirmContext === this.ENTOURAGE_CONFIRM_CONTEXT) {
      await this.confirmEntourageDeleteCallback(value)
    } else if (this.confirmContext === this.PAD_CONFIRM_CONTEXT) {
      await this.confirmPADDeleteCallback(value)
    } else {
      this.errorMessages = ['Error cannot confirm delete context ']
    }
  }

  private async confirmEntourageDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      const results = await this.pediatryService.deletePediatryEntourage(this.selectedItem.id)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        }).finally(() => {
          this.confirmContext = ''
        })

      if ((results as AxiosResponse<number>)?.status === 200) {
        this.errorMessages = []
        this.getEntourages()
      }
    }
  }

  // PAD

  public addPediatryPAD () {
    this.showPadCalenderDialog = true
  }

  public editPediatryPAD (pad: PediatryPADModel) {
    this.showPadCalenderDialog = true
    this.selectedPad = pad
  }

  public async savePediatryPAD () {
    const observer = this.$refs.dialogObserver as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isPadLoading = true
      const results = await this.pediatryService.UpsetPediatryPAD(this.dossierId, this.selectedPad)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        }).finally(() => { this.isPadLoading = false })
      if ((results as AxiosResponse<PediatryPADModel>)?.status === 200) {
        this.errorMessages = []
        this.getPADs()
        this.hidePAD()
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public hidePAD () {
    this.errorMessages = []
    this.selectedPad = { date: this.today }
    this.showPadCalenderDialog = false
  }

  public displayPADDeleteConfirmDialog (pad: PediatryPADModel) {
    this.showConfirm = true
    this.confirmContext = this.PAD_CONFIRM_CONTEXT
    this.selectedPad = pad
  }

  public async confirmPADDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      const results = await this.pediatryService.deletePediatryPAD(this.selectedPad.id)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        }).finally(() => {
          this.confirmContext = ''
        })

      if ((results as AxiosResponse<number>)?.status === 200) {
        this.errorMessages = []
        this.selectedPad = { date: this.today }
        this.getPADs()
      }
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
