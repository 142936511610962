import { Component, Vue } from 'vue-property-decorator'
import Consultations from '@/views/Patients/Overview/Consultations/Consultations.vue'
import EvaluationSituation from '@/views/Patients/Overview/Pediatrique/EvaluationSituations/EvaluationSituations.vue'
import PediatryEntourage from '@/views/Patients/Overview/Pediatrique/PediatryEntourages/PediatryEntourages.vue'
import MaterielPedia from '@/views/Patients/Overview/Pediatrique/MaterielPedia/MaterielPedia.vue'
import { ConsultationModel } from '@/models/consultation-model'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { PatientService } from '@/services/patient-service'
import { Subscription } from 'rxjs'
import Commons from '@/components/shared/Helpers/commons'
import { PediatryService } from '@/services/patient-pediatry-service'
import { ErrorService } from '@/services/error.service'

@Component({
  components: {
    Consultations,
    EvaluationSituation,
    PediatryEntourage,
    MaterielPedia
  }
})

export default class Pediatrique extends Vue {
  private patientService = PatientService.getInstance()
  private pediatryService = PediatryService.getInstance()
  private subscriptions: Subscription[] = []
  private selectedDossier!: dossierPatientModel
  private consultations: ConsultationModel[] = []
  public errorMessages: string[] = []

  public isLoading = false
  public tab = 0
  public tabs =
    [
      'Consultations',
      'Information à l’entourage',
      'Évaluation de situation',
      'Matériel'
    ]

  public created () {
    this.subscriptions.push(this.patientService.consultations$.subscribe(consArray => {
      this.consultations = consArray
    }))
  }

  public mounted () {
    this.subscriptions.push(this.patientService.dossierSelected$.subscribe(dossier => {
      this.selectedDossier = dossier
    }))
    this.ensureUserNavTitle()
    if (!this.consultations.length || this.consultations[0].dossierId !== this.selectedDossier.guid) {
      this.isLoading = true
      this.patientService.initDossierCollections(this.selectedDossier.guid)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isLoading = false
        })
    }
    this.tab = this.pediatryService.activeIndex
  }

  get consultationsPediatrique () {
    return this.consultations.filter(c => c.type === 4)
  }

  public ensureUserNavTitle () {
    if (this.selectedDossier?.guid) {
      Commons.updateDossierName()
    } else {
      this.$router.push('/patient')
    }
  }

  public tabChanged (index: any) {
    this.pediatryService.setTabIndex(index)
  }

  public showConsultation (item: ConsultationModel) {
    this.patientService.updateConsultationSelected(item)
    this.$router.push('/patient/consultation')
  }

  public async deleteConsultation (item: ConsultationModel) {
    this.isLoading = true
    await this.patientService.deleteDossierConsultation(item.id)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
    this.patientService.getDossierConsultations(this.selectedDossier.guid).finally(() => {
      this.isLoading = false
    })
  }

  public destroyed () {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe()
    })
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
