import { Component, Vue } from 'vue-property-decorator'
import { dossierPatientModel } from '@/models/dossier-response-model'
import Commons from '@/components/shared/Helpers/commons'
import { MaterielPediatriqueLibre, MaterielCategoryEnum } from '@/views/Patients/Dossier/Constants'
import MaterielLibre from '@/views/Patients/Materiel/MaterielLibre.vue'

@Component({
  components: {
    MaterielLibre
  }
})

export default class MaterielPedia extends Vue {
  private materielPediatriqueLibre = MaterielPediatriqueLibre
  private dossierId = ''
  private materielCategoryLibre = MaterielCategoryEnum.MaterielCategoryLibre

  public mounted () {
    const cachedDossier = sessionStorage.getItem('selectedDossier') as string
    const selectedDossier = JSON.parse(cachedDossier) as dossierPatientModel
    if (selectedDossier?.guid) {
      Commons.updateDossierName()
      this.dossierId = selectedDossier.guid
    } else {
      this.$router.push({ name: 'patient' })
    }
  }
}
