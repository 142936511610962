import { Component, Vue } from 'vue-property-decorator'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import EmptyTimeLine from '@/components/shared/EmptyTimeLine.vue'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { PediatrySituationModel } from '@/models/podiatry-observation-model'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { PediatryService } from '@/services/patient-pediatry-service'
import { AxiosResponse } from 'axios'
import { situationEvalueesTypes } from '@/views/Patients/Dossier/Constants'
import { AuthService } from '@/services/auth-service'
import Commons from '@/components/shared/Helpers/commons'

@Component({
  components: {
    Confirm,
    Alert,
    EmptyTimeLine
  }
})
export default class EvaluationSituation extends Vue {
  private pediatryService = PediatryService.getInstance()
  private userService = AuthService.getInstance()

  private selectedItem: PediatrySituationModel = {}
  private dossierId!: string

  public situationItems: PediatrySituationModel[] = []
  public showConfirm = false
  public initLoading = false
  public errorMessages: string[] = []

  public mounted () {
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (Commons.isCachedDossierPatientValid(cachedDossier)) {
      const selectedDossier = JSON.parse(cachedDossier!) as dossierPatientModel
      this.dossierId = selectedDossier.guid
      this.getEvaluations()
    } else {
      this.$router.push({ name: 'patient' })
    }
  }

  private getEvaluations () {
    this.initLoading = true
    this.pediatryService.GetDossierPediatrySituations(this.dossierId)
      .then(situations => {
        this.situationItems = situations
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.initLoading = false
      })
  }

  public getEvalText (id: number) {
    return situationEvalueesTypes.find(x => x.value === id)?.text
  }

  public addPediatrySituation () {
    const path = `/patient/${this.dossierId}/consultation/pediatry/situation/new`
    this.$router.push(path)
  }

  public editPediatrySituation (item: PediatrySituationModel) {
    this.selectedItem = item
    this.pediatryService.updatePediatrySituationSelected(this.selectedItem)
    const path = `/patient/${this.dossierId}/consultation/pediatry/situation/${item.id}`
    this.$router.push(path)
  }

  public canEditItem (situation: PediatrySituationModel) {
    return !situation.isLinkedToInvoice
  }

  public canDeleteItem (situation: PediatrySituationModel) {
    return !situation.isLinkedToInvoice && (this.userService.Roles.includes('NursingRecordsDelete') || (situation.date ? Commons.GetDate(situation.date)! >= Commons.TodayDate() : false))
  }

  public displayDeleteConfirmDialog (situation: PediatrySituationModel) {
    this.showConfirm = true
    this.selectedItem = situation
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      const results = await this.pediatryService.deletePediatrySituation(this.selectedItem.id)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })

      if ((results as AxiosResponse<any>)?.status === 200) {
        this.errorMessages = []
        this.getEvaluations()
      }
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
